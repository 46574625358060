
import Vue from 'vue'
import { IHeaderOption, KwikMediumContainer, KwikPageToolbar, KwikTableAction, KwikDialog } from 'kwik-vue'
import SupplierLookupSelector from '@/components/lookups/SupplierLookupSelector.vue'
import { BuyItem, BuyPayload, ISupplier } from '../types'
import { dispatchBuyFromSupplier } from '../store/actions'
import { ISupplierMaterial } from '@/shared/types/match'
import { readClock, readCurrentMatch } from '@/modules/match/store/getters'
import { clockToFormatDate } from '@/utils/clockManager'
import FFDateTimePicker from '@/components/FFDateTimePicker.vue'
import { commitKwikAddNotification } from 'kwik-vue/store/main/mutations'
import { RouteNames } from '@/router/names'

export default Vue.extend({
  name: 'FFSupplierTransactions',
  components: { KwikMediumContainer, SupplierLookupSelector, KwikPageToolbar, KwikTableAction, KwikDialog, FFDateTimePicker },
  data () {
    return {
      options: {} as any,
      loading: true,
      selectedSupplier: null as ISupplier | null,
      deliveryClock: 0,
      materialPrice: 0,
      materialQuantity: 0,
      materialLongTerm: false,
      materialPriceHint: '',
      items: [] as BuyItem[],
      supplierMaterials: [] as ISupplierMaterial[],
      selectableSupplierMaterials: [] as ISupplierMaterial[],
      showAddItem: false,
      selectedMaterial: null as ISupplierMaterial | null,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Acquisto materie prime', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Materiale',
          sortable: true,
          value: 'material_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Prezzo proposto',
          sortable: true,
          value: 'unit_price',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lunga scadenza',
          sortable: true,
          value: 'long_term',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità richiesta',
          sortable: true,
          value: 'public_quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'UM',
          sortable: true,
          value: 'unit_of_measure',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    }
  },
  methods: {
    handleSelectedSupplier (supplier: ISupplier) {
      this.selectedSupplier = supplier
      const clock = readClock(this.$store)
      console.log(clock)
      this.deliveryClock = clock
    },
    removeItem (item: BuyItem) {
      this.items = this.items.filter(i => i.material_id !== item.material_id)
    },
    async confirm () {
      if (!this.selectedSupplier) {
        commitKwikAddNotification(this.$store, {
          content: 'Seleziona il fornitore',
          color: 'error'
        })
        return
      }
      if (this.items.length <= 0) {
        commitKwikAddNotification(this.$store, {
          content: 'Seleziona almeno un materiale da acquistare',
          color: 'error'
        })
        return
      }
      if (this.deliveryClock < readClock(this.$store)) {
        commitKwikAddNotification(this.$store, {
          content: 'La data di consegna deve essere superiore alla data attuale',
          color: 'error'
        })
        return
      }
      const buyPayload: BuyPayload = {
        supplier_id: this.selectedSupplier.id,
        public_lead_time: this.deliveryClock,
        rows: this.items
      }
      await dispatchBuyFromSupplier(this.$store, buyPayload)
      commitKwikAddNotification(this.$store, {
        content: 'Ordine confermato',
        color: 'success'
      })
      await this.$router.push(
        {
          name: RouteNames.FF_SUPPLIER_TRANSACTIONS
        }
      )
    },
    addItem () {
      const match = readCurrentMatch(this.$store)
      debugger
      if (match == null || this.selectedSupplier == null) return
      const suppliers = match.activeUniverses[0].universe.suppliers
      const supplierId = this.selectedSupplier.id
      const supplier = suppliers.find(e => e.id === supplierId)
      if (supplier == null) return
      this.supplierMaterials = supplier.materials
      const selectableMaterials = [] as ISupplierMaterial[]
      const selectedMaterials = this.items
      supplier.materials.forEach(supplierMaterial => {
        let inCart = false
        selectedMaterials.forEach(selectedSupplierMaterial => {
          if (supplierMaterial.material.id === selectedSupplierMaterial.material_id) {
            inCart = true
          }
        })
        if (!inCart) {
          selectableMaterials.push(supplierMaterial)
        }
      })
      this.selectableSupplierMaterials = selectableMaterials
      this.selectedMaterial = null
      this.materialQuantity = 0
      this.materialLongTerm = false
      this.showAddItem = true
    },
    addToCart () {
      if (!this.selectedMaterial) return
      const item: BuyItem = {
        material_id: this.selectedMaterial.material.id,
        material_name: this.selectedMaterial.material.name,
        unit_price: this.materialPrice,
        long_term: this.materialLongTerm,
        public_quantity: this.materialQuantity,
        unit_of_measure: this.selectedMaterial.material.unit_of_measure
      }
      this.items.push(item)
      this.showAddItem = false
    },
    loadMaterialPriceAndQuantity () {
      if (this.selectedMaterial === null) {
        this.materialPriceHint = ''
        return
      }
      if (this.materialLongTerm === true) {
        this.materialPriceHint = 'Prezzo di listino: ' + this.selectedMaterial.unit_cost_long_term
        this.materialPrice = this.selectedMaterial.unit_cost_long_term
      } else {
        this.materialPriceHint = 'Prezzo di listino: ' + this.selectedMaterial.unit_cost
        this.materialPrice = this.selectedMaterial.unit_cost
      }
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    async cancel () {
      await this.$router.push(
        {
          name: RouteNames.FF_SUPPLIER_TRANSACTIONS
        }
      )
    }
  },
  watch: {
    materialLongTerm: {
      handler () {
        this.loadMaterialPriceAndQuantity()
      }
    },
    selectedMaterial: {
      handler () {
        this.loadMaterialPriceAndQuantity()
      },
      deep: true
    }
  }
})
